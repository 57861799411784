import React from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
    useLocation,
} from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {
    AppBar,
    Toolbar,
    Typography,
    Box,
    Drawer,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    IconButton,
} from "@mui/material";
import {
    Dashboard as DashboardIcon,
    Storage as NodesIcon,
    HourglassEmpty as PendingIcon,
    HighlightOff as ExpiredIcon,
    People as PeopleIcon,
    LocalOffer as PromosIcon,
    Receipt as InvoicesIcon,
    QueryStats as StatsIcon,
    AccessTime as UptimeIcon,
    Assignment as TasksIcon,
    Store as MerchantIcon,
    Warning as WarningIcon,
    Settings as SoftsIcon,
    Dns as DnsIcon,
    Diamond as DiamondIcon,
} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import Login from "./components/Login";
import PrivateRoute from "./utils/PrivateRoute";
import WorkerRoute from "./utils/WorkerRoute";
import PendingNodes from "./components/PendingNodes";
import AllUserNodes from "./components/UserNodes";
import Nodes from "./components/Nodes";
import Promos from "./components/Promos";
import Users from "./components/Users";
import Merchant from "./components/Merchant";
import Invoices from "./components/Invoices";
import NodeDetails from "./components/NodeDetails";
import SingleUserUserNodes from "./components/SingleUserUserNodes";
import Stats from "./components/Stats";
import Uptime from "./components/Uptime";
import WarningSettings from "./components/Warning";
import Tasks from "./components/Tasks";
import Dashboard from "./components/Dashboard";
import SoftsPage from "./components/Softs";
import ExpiredNodes from "./components/ExpiredNodes";
import Influencers from "./components/Influencers";
import InfluencerStats from "./components/InfluecerStats/ReferalProgram";
import SophonNodeSettings from "./components/SophonNodes";
import { Link } from "react-router-dom";
import { getCurrentUser } from "./services/authService";

const theme = createTheme({
    palette: {
        mode: "dark",
        background: {
            default: "#121212",
            paper: "#1d1d1d",
        },
        text: {
            primary: "#ffffff",
            secondary: "#aaaaaa",
        },
    },
    components: {
        MuiInputBase: {
            styleOverrides: {
                input: {
                    color: "#ffffff",
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: "#ffffff",
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    color: "#ffffff",
                },
            },
        },
    },
});

const Sidebar = () => {
    const location = useLocation();
    const user = getCurrentUser();
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const menuItems = [
        {
            text: "Dashboard",
            path: "/dashboard",
            icon: <DashboardIcon />,
            admin: true,
        },
        { text: "Nodes", path: "/nodes", icon: <NodesIcon />, admin: true },
        {
            text: "Pending Nodes",
            path: "/pending",
            icon: <PendingIcon />,
            admin: false,
        },
        {
            text: "Expired Nodes",
            path: "/expired",
            icon: <ExpiredIcon />,
            admin: true,
        },
        {
            text: "All Users Nodes",
            path: "/all-users-nodes",
            icon: <DnsIcon />,
            admin: false,
        },
        { text: "Promos", path: "/promos", icon: <PromosIcon />, admin: true },
        { text: "Users", path: "/users", icon: <PeopleIcon />, admin: true },
        {
            text: "Influencers",
            path: "/influencers",
            icon: <DiamondIcon />,
            admin: true,
        },
        {
            text: "Invoices",
            path: "/invoices",
            icon: <InvoicesIcon />,
            admin: true,
        },
        { text: "Uptime", path: "/uptime", icon: <UptimeIcon />, admin: false },
        { text: "Tasks", path: "/tasks", icon: <TasksIcon />, admin: true },
        {
            text: "Merchant",
            path: "/merchant",
            icon: <MerchantIcon />,
            admin: true,
        },
        {
            text: "Warning",
            path: "/warning",
            icon: <WarningIcon />,
            admin: true,
        },
        { text: "Stats", path: "/stats", icon: <StatsIcon />, admin: true },
        { text: "Softs", path: "/softs", icon: <SoftsIcon />, admin: false },
        { text: "Sophon", path: "/sophon", icon: <SoftsIcon />, admin: true },
    ];

    return (
        <>
            <AppBar position="static">
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={toggleDrawer}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6">xNode Admin</Typography>
                </Toolbar>
            </AppBar>
            <Drawer open={open} onClose={toggleDrawer}>
                <Box
                    sx={{ width: 250 }}
                    role="presentation"
                    onClick={toggleDrawer}
                    onKeyDown={toggleDrawer}
                >
                    <List>
                        {menuItems.map(
                            (item, index) =>
                                (user?.isSuperAdmin || !item.admin) && (
                                    <ListItem
                                        button
                                        key={index}
                                        component={Link}
                                        to={item.path}
                                        selected={
                                            location.pathname === item.path
                                        }
                                        sx={{
                                            "&.Mui-selected": {
                                                backgroundColor:
                                                    "rgba(255, 255, 255, 0.08)",
                                                "&:hover": {
                                                    backgroundColor:
                                                        "rgba(255, 255, 255, 0.15)",
                                                },
                                            },
                                        }}
                                    >
                                        <ListItemIcon>{item.icon}</ListItemIcon>
                                        <ListItemText primary={item.text} />
                                    </ListItem>
                                )
                        )}
                    </List>
                </Box>
            </Drawer>
        </>
    );
};

function App() {
    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Sidebar />
                <Box
                    sx={{
                        padding: "50px",
                        backgroundColor: theme.palette.background.default,
                        minHeight: "100vh",
                    }}
                >
                    <Routes>
                        <Route path="/login" element={<Login />} />
                        <Route
                            path="/nodes"
                            element={
                                <PrivateRoute>
                                    <Nodes />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/promos"
                            element={
                                <PrivateRoute>
                                    <Promos />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/users"
                            element={
                                <PrivateRoute>
                                    <Users />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/merchant"
                            element={
                                <PrivateRoute>
                                    <Merchant />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/invoices"
                            element={
                                <PrivateRoute>
                                    <Invoices />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/expired"
                            element={
                                <PrivateRoute>
                                    <ExpiredNodes />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/sophon"
                            element={
                                <PrivateRoute>
                                    <SophonNodeSettings />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/pending"
                            element={
                                <WorkerRoute>
                                    <PendingNodes />
                                </WorkerRoute>
                            }
                        />
                        <Route
                            path="/all-users-nodes"
                            element={
                                <WorkerRoute>
                                    <AllUserNodes />
                                </WorkerRoute>
                            }
                        />
                        <Route
                            path="/stats"
                            element={
                                <PrivateRoute>
                                    <Stats />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/dashboard"
                            element={
                                <PrivateRoute>
                                    <Dashboard />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/influencers"
                            element={
                                <PrivateRoute>
                                    <Influencers />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/uptime"
                            element={
                                <WorkerRoute>
                                    <Uptime />
                                </WorkerRoute>
                            }
                        />
                        <Route
                            path="/softs"
                            element={
                                <WorkerRoute>
                                    <SoftsPage />
                                </WorkerRoute>
                            }
                        />
                        <Route
                            path="/nodedetails/:nodeId"
                            element={
                                <PrivateRoute>
                                    <NodeDetails />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/influencer/:userId"
                            element={
                                <PrivateRoute>
                                    <InfluencerStats />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/user/:userId"
                            element={
                                <PrivateRoute>
                                    <SingleUserUserNodes />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/tasks"
                            element={
                                <PrivateRoute>
                                    <Tasks />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/warning"
                            element={
                                <PrivateRoute>
                                    <WarningSettings />
                                </PrivateRoute>
                            }
                        />
                        <Route path="/" element={<Navigate to="/pending" />} />
                        <Route
                            path="*"
                            element={<Navigate to="/not-found" />}
                        />
                    </Routes>
                </Box>
            </Router>
        </ThemeProvider>
    );
}

export default App;
