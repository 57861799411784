import React, { useState, useEffect } from "react";
import axiosInstance from "../utils/axiosConfig";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    Autocomplete,
    Pagination,
    Typography,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@mui/material";

const statuses = [
    "new",
    "pending",
    "pending internal",
    "mismatch",
    "completed",
    "error",
    "expired",
    "cancelled",
    "paid",
    "paid_over",
    "wrong_amount",
    "process",
    "confirm_check",
    "wrong_amount_waiting",
    "check",
    "fail",
    "cancel",
    "system_fail",
    "locked",
];

const InvoiceTable = () => {
    const [invoices, setInvoices] = useState([]);
    const [filters, setFilters] = useState({
        invoice_id: "",
        user_email: "",
        status: [],
        dateFrom: "",
        dateTo: "",
        tg: "",
        tgId: "",
        txid: "", // Добавлено поле txid
    });
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [selectedInvoice, setSelectedInvoice] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);

    useEffect(() => {
        fetchInvoices();
    }, [filters, page]);

    const fetchInvoices = async () => {
        try {
            const response = await axiosInstance.get("/admin/invoices", {
                params: { ...filters, page },
            });
            setInvoices(response.data.invoices);
            setTotalPages(response.data.totalPages);
        } catch (error) {
            console.error("Error fetching invoices:", error);
        }
    };

    const handleChange = (e) => {
        setFilters({
            ...filters,
            [e.target.name]: e.target.value,
        });
    };

    const handleStatusChange = (event, value) => {
        setFilters({
            ...filters,
            status: value.map((v) => v),
        });
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handleDialogOpen = (invoice) => {
        setSelectedInvoice(invoice);
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setSelectedInvoice(null);
    };

    const shortenTxid = (txid) => {
        if (!txid) return "";
        return `${txid.slice(0, 5)}...${txid.slice(-5)}`;
    };

    return (
        <div>
            <Typography sx={{ marginBottom: "20px", fontWeight: "700" }}>
                Invoices
            </Typography>
            <div style={{ padding: "16px", display: "flex", flexWrap: "wrap" }}>
                <TextField
                    label="Invoice ID"
                    name="invoice_id"
                    value={filters.invoice_id}
                    onChange={handleChange}
                    style={{ marginRight: "16px" }}
                />
                <TextField
                    label="User Email"
                    name="user_email"
                    value={filters.user_email}
                    onChange={handleChange}
                    style={{ marginRight: "16px" }}
                />
                <TextField
                    label="TG"
                    name="tg"
                    value={filters.tg}
                    onChange={handleChange}
                    style={{ marginRight: "16px" }}
                />
                <TextField
                    label="TG ID"
                    name="tgId"
                    value={filters.tgId}
                    onChange={handleChange}
                    style={{ marginRight: "16px" }}
                />
                <TextField
                    label="TXID" // Добавлено поле для фильтрации по txid
                    name="txid"
                    value={filters.txid}
                    onChange={handleChange}
                    style={{ marginRight: "16px" }}
                />
                <Autocomplete
                    multiple
                    options={statuses}
                    getOptionLabel={(option) => option}
                    onChange={handleStatusChange}
                    style={{ marginRight: "16px", width: "300px" }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Status"
                            variant="outlined"
                            style={{ marginRight: "16px" }}
                        />
                    )}
                />
                <TextField
                    label="Date From"
                    name="dateFrom"
                    type="date"
                    value={filters.dateFrom}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    style={{ marginRight: "16px" }}
                />
                <TextField
                    label="Date To"
                    name="dateTo"
                    type="date"
                    value={filters.dateTo}
                    onChange={handleChange}
                    InputLabelProps={{ shrink: true }}
                    style={{ marginRight: "16px" }}
                />
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Invoice ID</TableCell>
                            <TableCell>User</TableCell>
                            <TableCell>User ID</TableCell>
                            <TableCell>TG ID</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Total Price</TableCell>
                            <TableCell>TXID</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {invoices.map((invoice) => (
                            <TableRow key={invoice._id}>
                                <TableCell>{invoice.invoice_id}</TableCell>
                                <TableCell>
                                    {invoice.user.email ||
                                        `@${invoice.user.tg}`}
                                </TableCell>
                                <TableCell>{invoice.user._id}</TableCell>
                                <TableCell>{invoice.user.tgId}</TableCell>
                                <TableCell>{invoice.status}</TableCell>
                                <TableCell>
                                    {new Date(
                                        invoice.createdAt
                                    ).toLocaleDateString()}
                                </TableCell>
                                <TableCell>{invoice.totalPrice}</TableCell>
                                <TableCell>
                                    {shortenTxid(invoice.txid)}
                                </TableCell>
                                <TableCell>
                                    <Button
                                        variant="contained"
                                        onClick={() =>
                                            handleDialogOpen(invoice)
                                        }
                                    >
                                        View Details
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                sx={{
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                }}
            />
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
                aria-labelledby="dialog-title"
                aria-describedby="dialog-description"
                maxWidth="md"
                fullWidth
            >
                <DialogTitle id="dialog-title">Invoice Details</DialogTitle>
                <DialogContent dividers>
                    {selectedInvoice && (
                        <>
                            {selectedInvoice.invoice_id && (
                                <Typography
                                    id="dialog-description"
                                    sx={{ mt: 2 }}
                                >
                                    <strong>Invoice ID:</strong>{" "}
                                    {selectedInvoice.invoice_id}
                                </Typography>
                            )}
                            {selectedInvoice.user.email && (
                                <Typography
                                    id="dialog-description"
                                    sx={{ mt: 2 }}
                                >
                                    <strong>User:</strong>{" "}
                                    {selectedInvoice.user.email ||
                                        `@${selectedInvoice.user.tg}`}
                                </Typography>
                            )}
                            {selectedInvoice.user._id && (
                                <Typography
                                    id="dialog-description"
                                    sx={{ mt: 2 }}
                                >
                                    <strong>User ID:</strong>{" "}
                                    {selectedInvoice.user._id}
                                </Typography>
                            )}
                            {selectedInvoice.user.tgId && (
                                <Typography
                                    id="dialog-description"
                                    sx={{ mt: 2 }}
                                >
                                    <strong>TG ID:</strong>{" "}
                                    {selectedInvoice.user.tgId}
                                </Typography>
                            )}
                            {selectedInvoice.status && (
                                <Typography
                                    id="dialog-description"
                                    sx={{ mt: 2 }}
                                >
                                    <strong>Status:</strong>{" "}
                                    {selectedInvoice.status}
                                </Typography>
                            )}
                            {selectedInvoice.type && (
                                <Typography
                                    id="dialog-description"
                                    sx={{ mt: 2 }}
                                >
                                    <strong>Type:</strong>{" "}
                                    {selectedInvoice.type}
                                </Typography>
                            )}
                            {selectedInvoice.months && (
                                <Typography
                                    id="dialog-description"
                                    sx={{ mt: 2 }}
                                >
                                    <strong>Months:</strong>{" "}
                                    {selectedInvoice.months}
                                </Typography>
                            )}
                            {selectedInvoice.createdAt && (
                                <Typography
                                    id="dialog-description"
                                    sx={{ mt: 2 }}
                                >
                                    <strong>Date:</strong>{" "}
                                    {new Date(
                                        selectedInvoice.createdAt
                                    ).toLocaleDateString()}
                                </Typography>
                            )}
                            {selectedInvoice.totalPrice && (
                                <Typography
                                    id="dialog-description"
                                    sx={{ mt: 2 }}
                                >
                                    <strong>Total Price:</strong>{" "}
                                    {selectedInvoice.totalPrice}
                                </Typography>
                            )}
                            {selectedInvoice.txid && (
                                <Typography
                                    id="dialog-description"
                                    sx={{ mt: 2 }}
                                >
                                    <strong>TXID:</strong>{" "}
                                    {selectedInvoice.txid}
                                </Typography>
                            )}
                            {selectedInvoice.network && (
                                <Typography
                                    id="dialog-description"
                                    sx={{ mt: 2 }}
                                >
                                    <strong>Network:</strong>{" "}
                                    {selectedInvoice.network}
                                </Typography>
                            )}
                            {selectedInvoice.promoCodes.length > 0 && (
                                <Typography
                                    id="dialog-description"
                                    sx={{ mt: 2 }}
                                >
                                    <strong>Promos:</strong>{" "}
                                    {selectedInvoice.promoCodes
                                        .map((el) => el.code)
                                        .join(", ")}
                                </Typography>
                            )}
                            {selectedInvoice.items && (
                                <Typography
                                    id="dialog-description"
                                    sx={{ mt: 2 }}
                                >
                                    <strong>Items:</strong>
                                    <ul>
                                        {selectedInvoice.items.map(
                                            (item, index) => (
                                                <li key={index}>
                                                    {item.id && (
                                                        <>
                                                            <strong>ID:</strong>{" "}
                                                            {item.id}
                                                            <br />
                                                        </>
                                                    )}
                                                    {item.name && (
                                                        <>
                                                            <strong>
                                                                Name:
                                                            </strong>{" "}
                                                            {item.name}
                                                            <br />
                                                        </>
                                                    )}
                                                    <>
                                                        <strong>
                                                            Installation Price:
                                                        </strong>{" "}
                                                        {
                                                            item.installation_price
                                                        }
                                                        <br />
                                                    </>
                                                    {item.price_per_month && (
                                                        <>
                                                            <strong>
                                                                Price Per Month:
                                                            </strong>{" "}
                                                            {
                                                                item.price_per_month
                                                            }
                                                            <br />
                                                        </>
                                                    )}
                                                    {item.duration && (
                                                        <>
                                                            <strong>
                                                                Duration:
                                                            </strong>{" "}
                                                            {item.duration}
                                                            <br />
                                                        </>
                                                    )}
                                                    {item.quantity && (
                                                        <>
                                                            <strong>
                                                                Quantity:
                                                            </strong>{" "}
                                                            {item.quantity}
                                                            <br />
                                                        </>
                                                    )}
                                                    {item.deployType && (
                                                        <>
                                                            <strong>
                                                                Deploy Type:
                                                            </strong>{" "}
                                                            {item.deployType}
                                                            <br />
                                                        </>
                                                    )}

                                                    <>
                                                        <strong>
                                                            Discount Percentage:
                                                        </strong>{" "}
                                                        {
                                                            item.discount_percentage
                                                        }
                                                        <br />
                                                    </>
                                                </li>
                                            )
                                        )}
                                    </ul>
                                </Typography>
                            )}
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default InvoiceTable;
