import React, { useState, useEffect } from "react";
import axiosInstance from "../utils/axiosConfig";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Checkbox,
    FormControlLabel,
    Typography,
    Stack,
    Pagination,
} from "@mui/material";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";

const UserSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email"),
    tg: Yup.string(),
    banned: Yup.boolean(),
    isVerified: Yup.boolean(),
});

const Influencers = () => {
    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState("");
    const [id, setId] = useState("");
    const [tgId, setTgId] = useState("");
    const [tg, setTg] = useState("");
    const [open, setOpen] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        fetchUsers();
    }, [id, search, tgId, tg, page]);

    const fetchUsers = async () => {
        try {
            const response = await axiosInstance.get("/admin/influencers", {
                params: { id, search, tgId, tg, page, limit: 10 },
            });
            setUsers(response.data.users);
            setTotalPages(response.data.totalPages);
        } catch (error) {
            console.error("Error fetching users:", error);
        }
    };

    const handleOpen = (user = null) => {
        setCurrentUser(user);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setCurrentUser(null);
    };

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            if (currentUser) {
                await axiosInstance.put(
                    `/admin/user/${currentUser._id}`,
                    values
                );
            } else {
                await axiosInstance.post("/admin/new-user", values);
            }
            fetchUsers();
            handleClose();
        } catch (error) {
            console.error("Error saving user:", error);
        } finally {
            setSubmitting(false);
        }
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const getCellStyle = (value) => {
        return {
            color: value === "Yes" ? "green" : "red",
        };
    };

    return (
        <div>
            <Typography sx={{ marginBottom: "20px", fontWeight: "700" }}>
                Influencers
            </Typography>
            <Stack
                direction="row"
                sx={{ alignItems: "center", justifyContent: "space-between" }}
            >
                <div style={{ display: "flex", gap: "2rem" }}>
                    <TextField
                        label="Поиск по id"
                        value={id}
                        onChange={(e) => setId(e.target.value)}
                        variant="outlined"
                        margin="normal"
                    />
                    <TextField
                        label="Email Search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        variant="outlined"
                        margin="normal"
                    />
                    <TextField
                        label="Поиск по tgId"
                        value={tgId}
                        onChange={(e) => setTgId(e.target.value)}
                        variant="outlined"
                        margin="normal"
                    />
                    <TextField
                        label="Поиск по tg"
                        value={tg}
                        onChange={(e) => setTg(e.target.value)}
                        variant="outlined"
                        margin="normal"
                    />
                </div>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleOpen()}
                >
                    Create User
                </Button>
            </Stack>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Telegram</TableCell>
                            <TableCell>Tg ID</TableCell>
                            <TableCell>Balance</TableCell>
                            <TableCell>Refback Percent</TableCell>
                            <TableCell>Total Amount</TableCell>
                            <TableCell>Month Amount</TableCell>
                            <TableCell>Month Refback</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((user) => (
                            <TableRow key={user._id}>
                                <TableCell>{user._id}</TableCell>
                                <TableCell>{user.email}</TableCell>
                                <TableCell>{user.tg}</TableCell>
                                <TableCell>{user.tgId}</TableCell>
                                <TableCell>${user.balance}</TableCell>
                                <TableCell>{user.refBackPercent}%</TableCell>
                                <TableCell>
                                    {user.totalAmount.toFixed(2)}$
                                </TableCell>
                                <TableCell>
                                    {user.monthlyAmount.toFixed(2)}$
                                </TableCell>
                                <TableCell>
                                    {user.monthlyPaidAmount.toFixed(2)}$
                                </TableCell>
                                <TableCell>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => handleOpen(user)}
                                    >
                                        Edit
                                    </Button>
                                    <Link
                                        to={`/user/${user._id}`}
                                        style={{
                                            color: "white",
                                            textDecoration: "none",
                                            border: "1px solid #1565c0",
                                            backgroundColor: "#1565c0",
                                            marginLeft: "10px",
                                            fontWeight: "500",
                                            fontSize: "0.875rem",
                                            lineHeight: "1.75",
                                            letterSpacing: "0.02857em",
                                            textTransform: "uppercase",
                                            minWidth: "64px",
                                            padding: "6px 16px",
                                            borderRadius: "4px",
                                        }}
                                    >
                                        Nodes
                                    </Link>
                                    <Link
                                        to={`/influencer/${user._id}`}
                                        style={{
                                            color: "white",
                                            textDecoration: "none",
                                            border: "1px solid #708090",
                                            backgroundColor: "#708090",
                                            marginLeft: "10px",
                                            fontWeight: "500",
                                            fontSize: "0.875rem",
                                            lineHeight: "1.75",
                                            letterSpacing: "0.02857em",
                                            textTransform: "uppercase",
                                            minWidth: "64px",
                                            padding: "6px 16px",
                                            borderRadius: "4px",
                                        }}
                                    >
                                        Stats
                                    </Link>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                sx={{
                    marginTop: "20px",
                    display: "flex",
                    justifyContent: "center",
                }}
            />
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>
                    {currentUser ? "Edit User" : "Create User"}
                </DialogTitle>
                <DialogContent>
                    <Formik
                        initialValues={{
                            email: currentUser ? currentUser.email : "",
                            tg: currentUser ? currentUser.tg : "",
                            banned: currentUser ? currentUser.banned : false,
                            isVerified: currentUser
                                ? currentUser.isVerified
                                : false,
                            influencer: currentUser
                                ? currentUser.influencer
                                : false,
                            balance: currentUser ? currentUser.balance : 0,
                            refBackPercent: currentUser
                                ? currentUser.refBackPercent
                                : 0,
                        }}
                        validationSchema={UserSchema}
                        onSubmit={handleSubmit}
                    >
                        {({ errors, touched }) => (
                            <Form>
                                <Field
                                    name="email"
                                    as={TextField}
                                    label="Email"
                                    fullWidth
                                    margin="normal"
                                    error={touched.email && !!errors.email}
                                    helperText={touched.email && errors.email}
                                />
                                <Field
                                    name="tg"
                                    as={TextField}
                                    label="Telegram"
                                    fullWidth
                                    margin="normal"
                                    error={touched.tg && !!errors.tg}
                                    helperText={touched.tg && errors.tg}
                                />
                                <Field
                                    name="balance"
                                    as={TextField}
                                    label="Balance"
                                    fullWidth
                                    margin="normal"
                                    error={touched.balance && !!errors.balance}
                                    helperText={
                                        touched.balance && errors.balance
                                    }
                                />
                                <Field
                                    name="refBackPercent"
                                    as={TextField}
                                    label="Ref Back Percent"
                                    fullWidth
                                    margin="normal"
                                    error={
                                        touched.refBackPercent &&
                                        !!errors.refBackPercent
                                    }
                                    helperText={
                                        touched.refBackPercent &&
                                        errors.refBackPercent
                                    }
                                />
                                <Field name="banned">
                                    {({ field }) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    {...field}
                                                    checked={field.value}
                                                />
                                            }
                                            label="Banned"
                                        />
                                    )}
                                </Field>
                                <Field name="isVerified">
                                    {({ field }) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    {...field}
                                                    checked={field.value}
                                                />
                                            }
                                            label="Verified"
                                        />
                                    )}
                                </Field>
                                <Field name="influencer">
                                    {({ field }) => (
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    {...field}
                                                    checked={field.value}
                                                />
                                            }
                                            label="Influencer"
                                        />
                                    )}
                                </Field>
                                <DialogActions>
                                    <Button
                                        onClick={handleClose}
                                        color="primary"
                                        sx={{ marginRight: "10px" }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button type="submit" color="primary">
                                        Save
                                    </Button>
                                </DialogActions>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default Influencers;
