import React, { useState, useEffect } from "react";
import axiosInstance from "../utils/axiosConfig";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
    Checkbox,
    Button,
    FormControlLabel,
    Typography,
    TextField,
    List,
    ListItem,
    ListItemText,
    IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

const SophonNodeSchema = Yup.object().shape({
    address: Yup.string().required("Required"),
    slotsEmpty: Yup.boolean().required("Required"),
});

const SophonNodeSettings = () => {
    const [initialValues, setInitialValues] = useState({
        address: "",
        slotsEmpty: false,
    });

    const [nodes, setNodes] = useState([]);
    const [editingNode, setEditingNode] = useState(null);

    useEffect(() => {
        const fetchNodes = async () => {
            const response = await axiosInstance.get("/sophonNodes");
            if (response.data) {
                setNodes(response.data);
            }
        };
        fetchNodes();
    }, []);

    const handleSubmit = async (values, { resetForm }) => {
        if (editingNode) {
            await axiosInstance.put(`/sophonNodes/${editingNode._id}`, values);
        } else {
            await axiosInstance.post("/sophonNodes", values);
        }
        resetForm();
        setEditingNode(null);
        const response = await axiosInstance.get("/sophonNodes");
        setNodes(response.data);
    };

    const handleEdit = (node) => {
        setInitialValues({
            address: node.address,
            slotsEmpty: node.slotsEmpty,
        });
        setEditingNode(node);
    };

    return (
        <div>
            <Typography sx={{ marginBottom: "20px", fontWeight: "700" }}>
                Sophon Nodes
            </Typography>
            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={SophonNodeSchema}
                onSubmit={handleSubmit}
            >
                {({ values, handleChange, setFieldValue }) => (
                    <Form>
                        <Field name="address">
                            {({ field }) => (
                                <TextField
                                    {...field}
                                    label="Address"
                                    variant="outlined"
                                    fullWidth
                                    margin="normal"
                                />
                            )}
                        </Field>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    name="slotsEmpty"
                                    checked={values.slotsEmpty}
                                    onChange={handleChange}
                                />
                            }
                            label="Slots Empty"
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            sx={{ marginTop: "20px" }}
                        >
                            {editingNode ? "Update" : "Create"}
                        </Button>
                    </Form>
                )}
            </Formik>
            <List sx={{ marginTop: "20px" }}>
                {nodes.map((node) => (
                    <ListItem key={node._id}>
                        <ListItemText
                            primary={`Address: ${node.address}`}
                            secondary={`Slots Empty: ${node.slotsEmpty}`}
                        />
                        <IconButton
                            edge="end"
                            aria-label="edit"
                            onClick={() => handleEdit(node)}
                        >
                            <EditIcon />
                        </IconButton>
                    </ListItem>
                ))}
            </List>
        </div>
    );
};

export default SophonNodeSettings;
